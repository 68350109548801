<template>
  <div class="daily_quiz_manage_area">
    <div class="reg_daily_quiz_area">
      <p class="reg_daily_quiz_label">관심있는 퀴즈를 일일 퀴즈로 등록할 수 있습니다.</p>
      <p class="reg_daily_quiz_sub_label">💡 선택 모드 : 선택한 퀴즈만 일일 퀴즈로 등록</p>
      <p class="reg_daily_quiz_sub_label">💡 랜덤 모드 : 관심있는 퀴즈 전체 매일 일일 퀴즈로 랜덤 N문제</p>
      <button id="regDailyQuiz" @click="openDailyQuizRegModal">등록하기</button>
    </div>

    <div class="my_daily_quiz_info_area">
      <div class="left_area">

        <div class="my_daily_quiz_area" >
          <p class="area_label">내 일일 퀴즈</p>
          <DailyQuizSwiper @setDailyQuizExist="setDailyQuizExist" @setDailyQuizIntegratedInfo="setDailyQuizIntegratedInfo"/>
        </div>

        <div class="my_daily_quiz_history_calendar_area">
          <p class="area_label">월간 수행표</p>
          <div class="calendar_area">
            <DailyQuizCalendar :dailyQuizExist="dailyQuizExist" @setDailyQuizAnswerHistoryItemList="setDailyQuizAnswerHistoryItemList" @setDailyQuizInfoItemList="setDailyQuizInfoItemList"/>
          </div>
        </div>

        <div class="stat_count_area not-m">
            <div class="stat_1">
              <p class="area_label">한달 권장 수행수</p>
              <div v-if="dailyQuizExist === true">
                <div class="month_act_cnt circle_chart">
                  <RadialProgress
                      :diameter="130"
                      :completed-steps="monthActCnt"
                      :total-steps="10"
                      :startColor="'#EB85FF'"
                      :stopColor="'#EB85FF'"
                      :animateSpeed="400"
                      :innerStrokeColor="'#D0F0E5'"
                      :innerStrokeWidth="12"
                      :strokeWidth="12"
                      :fps="100">
                    <div class="circle_chart_info">
                      <span class="current_month">{{ $dateUtils.dateToStringYYYYMM(new Date()) }}</span><br/>
                      <span class="month_act_cnt">{{ monthActCnt }} / 10</span>
                    </div>
                  </RadialProgress>
                </div>
              </div>
              <div v-else-if="dailyQuizExist === false">
                <div class="nothing">
                  <p class="nothing_txt">선택된 일일 퀴즈가 <br class="nothing_txt_br"/>없습니다.</p>
                </div>
              </div>
            </div>
            <div class="stat_2">
              <p class="area_label">일일 퀴즈 리스트</p>
              <div v-if="dailyQuizExist === true">
              <ul class="info_item_list">
                <template v-for="(dailyQuizInfoItem, index) in dailyQuizInfoItemList">
                  <li class="info_item">
                    <div class="title_area">
                      <span :class="'num seq' + (index + 1) ">{{ index + 1 }}</span><span class="title">{{ dailyQuizInfoItem.title }}</span>
                    </div>
                  </li>
                </template>
              </ul>
              </div>
              <div v-else-if="dailyQuizExist === false">
                <div class="nothing">
                  <p class="nothing_txt">선택된 일일 퀴즈가 없습니다.</p>
                </div>
              </div>
            </div>
            <div class="stat_3">
              <p class="area_label">목표 수행수</p>

              <div v-if="dailyQuizExist === true">
                <div class="completion_cnt circle_chart">
                  <RadialProgress
                      :diameter="130"
                      :completed-steps="actCnt"
                      :total-steps="completionCnt"
                      :startColor="'#ef6d73'"
                      :stopColor="'#ef6d73'"
                      :animateSpeed="400"
                      :innerStrokeColor="'#D0F0E5'"
                      :innerStrokeWidth="12"
                      :strokeWidth="12"
                      :fps="100">
                    <div class="circle_chart_info">
                      <span class="current_month">{{ actRate }}%</span><br/>
                      <span class="act_cnt">{{ actCnt }} / {{completionCnt }}</span>
                    </div>
                  </RadialProgress>
                </div>
              </div>
              <div v-else-if="dailyQuizExist === false">
                <div class="nothing">
                  <p class="nothing_txt">선택된 일일 퀴즈가 <br class="nothing_txt_br"/>없습니다.</p>
                </div>
              </div>
            </div>
        </div>

        <div class="stat_count_area m">
          <div class="stat_2">
            <p class="area_label">일일 퀴즈 리스트</p>
            <div v-if="dailyQuizExist === true">
              <ul class="info_item_list">
                <template v-for="(dailyQuizInfoItem, index) in dailyQuizInfoItemList">
                  <li class="info_item">
                    <div class="title_area">
                      <span :class="'num seq' + (index + 1) ">{{ index + 1 }}</span><span class="title">{{ dailyQuizInfoItem.title }}</span>
                    </div>
                  </li>
                </template>
              </ul>
            </div>
            <div v-else-if="dailyQuizExist === false">
              <div class="nothing">
                <p class="nothing_txt">선택된 일일 퀴즈가 없습니다.</p>
              </div>
            </div>
          </div>
          <div class="sub_1">
            <div class="stat_1">
              <p class="area_label">한달 권장 수행수</p>

              <div v-if="dailyQuizExist === true">
                <div class="month_act_cnt circle_chart">
                  <RadialProgress
                      :diameter="130"
                      :completed-steps="monthActCnt"
                      :total-steps="10"
                      :startColor="'#EB85FF'"
                      :stopColor="'#EB85FF'"
                      :animateSpeed="400"
                      :innerStrokeColor="'#D0F0E5'"
                      :innerStrokeWidth="12"
                      :strokeWidth="12"
                      :fps="100">
                    <div class="circle_chart_info">
                      <span class="current_month">{{ $dateUtils.dateToStringYYYYMM(new Date()) }}</span><br/>
                      <span class="month_act_cnt">{{ monthActCnt }} / 10</span>
                    </div>
                  </RadialProgress>
                </div>
              </div>
              <div v-else-if="dailyQuizExist === false">
                <div class="nothing">
                  <p class="nothing_txt">선택된 일일 퀴즈가 <br class="nothing_txt_br"/>없습니다.</p>
                </div>
              </div>
            </div>
            <div class="stat_3">
              <p class="area_label">목표 수행수</p>
              <div v-if="dailyQuizExist === true">
                <div class="completion_cnt circle_chart">
                  <RadialProgress
                      :diameter="130"
                      :completed-steps="actCnt"
                      :total-steps="completionCnt"
                      :startColor="'#ef6d73'"
                      :stopColor="'#ef6d73'"
                      :animateSpeed="400"
                      :innerStrokeColor="'#D0F0E5'"
                      :innerStrokeWidth="12"
                      :strokeWidth="12"
                      :fps="100">
                    <div class="circle_chart_info">
                      <span class="current_month">{{ actRate }}%</span><br/>
                      <span class="act_cnt">{{ actCnt }} / {{completionCnt }}</span>
                    </div>
                  </RadialProgress>
                </div>
              </div>
              <div v-else-if="dailyQuizExist === false">
                <div class="nothing">
                  <p class="nothing_txt">선택된 일일 퀴즈가 <br class="nothing_txt_br"/>없습니다.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="right_area">
        <div class="my_daily_quiz_history_answer_area">
          <p class="area_label">답변 이력</p>
          <div v-if="dailyQuizExist === true">
          <ul class="answer_history_item_list" v-if="dailyQuizAnswerHistoryItemList.length > 0">
            <template v-for="(dailyQuizAnswerHistoryItem, index) in dailyQuizAnswerHistoryItemList">
              <li class="history_item">
                <div class="quiz_info_area">
                  <div class="title_area">
                    <span :class="'num seq' + (index + 1) ">{{ index + 1 }}</span>
                    <span class="title">{{ dailyQuizAnswerHistoryItem.title }}</span>
                  </div>
                  <br/>
                  <p class="content">{{ dailyQuizAnswerHistoryItem.content }}</p>
                  <span class="answer">작성한 답변 : {{ dailyQuizAnswerHistoryItem.answer }}</span>
                </div>
              </li>
            </template>
          </ul>
          <p class="answer_history_no_data" v-else>답변한 이력이 없습니다.</p>
          </div>
          <div v-else-if="dailyQuizExist === false">
            <div class="nothing">
              <p class="nothing_txt">선택된 일일 퀴즈가 없습니다.</p>
            </div>
          </div>
          <LoaderItem/>
        </div>
      </div>
    </div>
  </div>

  <Footer/>

  <DailyQuizRegModal @closeDailyQuizRegModal="closeDailyQuizRegModal" v-if="dailyQuizRegModalOn"></DailyQuizRegModal>
</template>

<script>
import Loader from "@/components/common/Loader";

import DailyQuizSwiper from "@/components/main/manage/component/DailyQuizSwiper.vue";
import DailyQuizCalendar from "@/components/main/manage/component/DailyQuizCalendar.vue";
import RadialProgress from "vue3-radial-progress";
import DailyQuizRegModal from "@/components/modal/manage/DailyQuizRegModal";
import LoaderItem from "@/components/common/LoaderItem";
import Footer from "@/components/layout/Footer.vue";
import apiClient from "@/plugins/apiClient";
import {toastFail} from "@/utils/toastUtils";

export default {
  name: 'DailyQuizManage',
  components: {Footer, LoaderItem, DailyQuizRegModal, DailyQuizCalendar, DailyQuizSwiper, Loader, RadialProgress},
  data: () => ({
    dailyQuizInfoItemList: [],
    dailyQuizAnswerHistoryItemList: [],
    monthActCnt : 0,
    completionCnt : 0,
    actCnt : 0,
    dailyQuizRegModalOn : false,
    dailyQuizExist: null
  }),
  methods: {
    setDailyQuizIntegratedInfo(dailyQuizIntegratedInfo){
      this.dailyQuizInfoItemList = dailyQuizIntegratedInfo.dailyQuizInfoItems;

      this.monthActCnt = dailyQuizIntegratedInfo.monthActCnt > 10
          ? 10
          : dailyQuizIntegratedInfo.monthActCnt;

      let completionCnt = dailyQuizIntegratedInfo.dailyQuizCompletionCntInfo.completionCnt;
      let actCnt = dailyQuizIntegratedInfo.dailyQuizCompletionCntInfo.actCnt;

      actCnt = actCnt > completionCnt ? completionCnt : actCnt;

      this.completionCnt = completionCnt;
      this.actCnt = actCnt ;
      this.actRate = Math.floor((actCnt / completionCnt) * 100);
    },
    setDailyQuizAnswerHistoryItemList(dailyQuizAnswerHistoryItemList){
      if(dailyQuizAnswerHistoryItemList === null || dailyQuizAnswerHistoryItemList.length == 0){
        this.dailyQuizAnswerHistoryItemList = [];
        return false;
      }

      let dailyQuizInfoItemList = this.dailyQuizInfoItemList;
      let dailyQuizAnswerHistoryItemTempList = [];

      if(dailyQuizAnswerHistoryItemList.length > 0){
        dailyQuizAnswerHistoryItemList.forEach(function(data){
          let quizInfoWithAnswerHistoryItem = {};
          if(dailyQuizInfoItemList != null){
            let filteredQuizInfo = dailyQuizInfoItemList.filter(x => x.quizId == data.quizId);

            quizInfoWithAnswerHistoryItem.quizId = data.quizId;
            quizInfoWithAnswerHistoryItem.title = filteredQuizInfo[0].title;
            quizInfoWithAnswerHistoryItem.content = filteredQuizInfo[0].content;
            quizInfoWithAnswerHistoryItem.answer = data.answer;

            dailyQuizAnswerHistoryItemTempList.push(quizInfoWithAnswerHistoryItem);
          }
        });

        this.dailyQuizAnswerHistoryItemList = dailyQuizAnswerHistoryItemTempList;
      }
    },
    setDailyQuizInfoItemList(dailyQuizInfoItemList){
      this.dailyQuizInfoItemList = dailyQuizInfoItemList;
    },
    openDailyQuizRegModal(){
      apiClient.get('/api/daily-quiz/create/available/count').then(data => {
        let likedQuizCnt = data.data.resData;
        if(likedQuizCnt > 0){
          this.dailyQuizRegModalOn = true;
        } else if(likedQuizCnt < 1){
          toastFail("관심있는 퀴즈가 존재하지 않습니다.");
        }
      })
    },
    closeDailyQuizRegModal(){
      this.dailyQuizRegModalOn = false;
    },
    setDailyQuizExist(existFlag){
      this.dailyQuizExist = existFlag;
    }
  }
}
</script>

<style>
  @import "@/assets/main/manage/DailyQuizManage.css";
</style>