import axios from "axios";
import store from "@/store";
import router from "@/utils/router";
import {toastFail} from "@/utils/toastUtils";


const apiClient = axios.create({
    /*timeout: 1000,*/
});

// 응답 인터셉터 설정
apiClient.interceptors.response.use(function (res) {
    if(res.data.resCode === '99'){
        toastFail(res.data.resMessage);
        $('.loader_div').hide();
        return Promise.reject("SERVER ERROR");
    }
    

    if(res.data.resCode === '999'){
        toastFail(res.data.resMessage);
        $('.loader_div').hide();
        return Promise.reject("PARAMETER VALIDATION ERROR");
    }

    if(res.data.resCode === '9999'){
        toastFail(res.data.resMessage);
        store.dispatch('logout');
        router.push('/');

        $('.loader_div').hide();

        return Promise.reject("AUTH ERROR");
    }

    return res;
}, function (error) {
    toastFail("알 수 없는 오류가 발생하였습니다.");

    $('.loader_div').hide();
    return Promise.reject(error);
})

export default apiClient;