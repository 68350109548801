<template>
  <div>
    <div v-if="myDailyQuizList != null">
      <swiper
          ref="swiperRef"
          navigation
          :slidesPerView="1.4"
          :centeredSlides="true"
          :pagination="{ clickable: true }"
          :slideToClickedSlide="true"
          :watchOverflow="false"
          :spaceBetween="20"
          class="daily_quiz_swiper"
          @slide-change="publishMoveEvent"
          @swiper="onSwiper">
        <template v-for="myDailyQuizListItem in myDailyQuizList">
          <swiper-slide>
            <div class="daily_quiz_item">
              <div class="delete_btn_area">
                <button class="delete_btn" @click="deleteDailyQuiz(myDailyQuizListItem.dailyQuizId)"></button>
              </div>

              <input type="hidden" name="dailyQuizId" :value="myDailyQuizListItem.dailyQuizId"/>
              <p class="description"> {{ myDailyQuizListItem.name }} </p>
              <p class="reg_quiz_cnt" v-if="myDailyQuizListItem.mode === 'S'">등록된 문제 {{ myDailyQuizListItem.quizCnt }}개</p>
              <p class="reg_quiz_cnt" v-if="myDailyQuizListItem.mode === 'R'">랜덤 문제 하루 {{ myDailyQuizListItem.dailyCnt }}개</p>
              <p class="solve_count">푼 횟수  {{ myDailyQuizListItem.actCnt }}번 </p>
              <div class="btn_area">
                <button class="flag_btn on" v-if="myDailyQuizListItem.onYn == 1" @click="switchFlag(myDailyQuizListItem.dailyQuizId, 'Y')">ON</button>
                <button class="flag_btn off" v-else @click="switchFlag(myDailyQuizListItem.dailyQuizId, 'N')">OFF</button>
              </div>
            </div>
          </swiper-slide>
        </template>
      </swiper>
    </div>
    <div class="nothing" v-else>
      <p class="nothing_txt">
        일일 퀴즈가 존재하지 않습니다.
      </p>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/common/Loader";
import apiClient from "@/plugins/apiClient";
import {Swiper, SwiperSlide} from 'vue-awesome-swiper'
import SwiperCore, {Navigation, Pagination} from 'swiper';

import 'swiper/css'
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import LoaderItem from "@/components/common/LoaderItem";
import {isNullOrEmptyStr} from "@/utils/validationUtils";
import {toastFail} from "@/utils/toastUtils";

SwiperCore.use([Navigation, Pagination]);

export default {
  props: {
    dailyQuizInfoItemList: {
      type: Array,
      default: []
    }
  },
  name: 'DailyQuizSwiper',
  components: {LoaderItem, Loader, Swiper, SwiperSlide},
  data: () => ({
    myDailyQuizList: {},
    swiper: null,
    initSlider: 0
  }),
  mounted() {
    this.getMyDailyQuizList();


    this.emitter.on("getMyDailyQuizList",(param) => {
      this.getMyDailyQuizList(param);
    });
  },
  methods: {
    onSwiper(swiper) {
      this.swiper = swiper;
    },
    getMyDailyQuizList() {
      $('.loader_div').show();
      apiClient.get('/api/daily-quizzes').then(data => {
        let result = data.data.resData;

        if(result.length > 0){
          this.myDailyQuizList = result;
          this.initSlider = this.myDailyQuizList.findIndex( (x) => x.onYn == true );
          this.swiper.slideTo(this.initSlider);

          this.emitter.emit("calendarResetToday");

          let onDailyQuizData = this.myDailyQuizList.filter( (x) => x.onYn == true )
          let onDailyQuizId = onDailyQuizData != null && onDailyQuizData.length > 0
              ? onDailyQuizData[0].dailyQuizId
              : this.myDailyQuizList[0].dailyQuizId;



          if(onDailyQuizData !== null && onDailyQuizData.length > 0){
            this.$store.dispatch('setDailyQuizId', onDailyQuizData[0].dailyQuizId);
          }else{
            this.$store.dispatch('setDailyQuizId', 0);
          }


          let emitMap = {};
          emitMap.dailyQuizId = onDailyQuizId;
          emitMap.searchDate = this.$dateUtils.dateToString(new Date());
          this.emitter.emit("getMonthAnswerHistoryDateList", emitMap);

          this.getDailyQuizIntegratedInfo(onDailyQuizId);
          this.$emit('setDailyQuizExist', true);
        }else{
          this.myDailyQuizList = null;
          this.$emit('setDailyQuizExist', false);
          this.$store.dispatch('setDailyQuizId', 0);
        }

        $('.loader_div').hide();
      });
    },
    switchFlag(dailyQuizId, onYn){
      if(dailyQuizId == null){
        toastFail("에러가 발생하였습니다.");
        return false
      }

      if(isNullOrEmptyStr(onYn)){
        toastFail("에러가 발생하였습니다.");
        return false
      }

      let params = {};
      params.dailyQuizId = dailyQuizId;
      params.onYn = onYn == true ? false : true;

      apiClient.post('/api/daily-quiz/switch', params).then(data => {
        if(data.data.resCode == '00'){
          location.reload();
        }
      });
    },
    deleteDailyQuiz(dailyQuizId){
      if(dailyQuizId == null){
        toastFail("에러가 발생하였습니다.");
        return false
      }

      let params = {};
      params.dailyQuizId = dailyQuizId;

      if(confirm("일일 퀴즈를 삭제하시겠습니까?")) {
        apiClient.delete('/api/daily-quiz/' + dailyQuizId).then(data => {
          location.reload();
        });
      }
    },
    publishMoveEvent($event){
      let activeDailyQuizId = $('.swiper-slide:nth-child('+ ($event.activeIndex + 1) + ') .daily_quiz_item input[name=dailyQuizId]').val();

      this.emitter.emit("calendarResetToday");

      let emitMap = {};
      emitMap.dailyQuizId = activeDailyQuizId;
      emitMap.searchDate = this.$dateUtils.dateToString(new Date());
      this.emitter.emit("getMonthAnswerHistoryDateList", emitMap);

      this.emitter.emit("moveCalendar", new Date());

      this.getDailyQuizIntegratedInfo(activeDailyQuizId);
    },
    getDailyQuizIntegratedInfo(activeDailyQuizId){
      if(activeDailyQuizId == null){
        let onDailyQuizData = this.myDailyQuizList.filter( (x) => x.onYn == true );
        activeDailyQuizId = onDailyQuizData != null
            ? onDailyQuizData[0].dailyQuizId
            : this.myDailyQuizList[0].dailyQuizId;
      }

      let params = {};
      params.dailyQuizId = activeDailyQuizId;

      apiClient.get('/api/daily-quiz/integrated/' + activeDailyQuizId, {params : params}).then(data => {
        let dailyQuizIntegratedInfo = data.data.resData;

        if(dailyQuizIntegratedInfo != null){

          if(dailyQuizIntegratedInfo.mode === 'R'){
            let param = {};
            param.searchDate = this.$dateUtils.dateToString(new Date());
            param.mode = dailyQuizIntegratedInfo.mode;
            this.$emit('setDailyQuizIntegratedInfo', dailyQuizIntegratedInfo)
            this.emitter.emit("getRandomQuizHistory", param);
          }else{
            let param = {};
            param.searchDate = this.$dateUtils.dateToString(new Date());
            param.mode = dailyQuizIntegratedInfo.mode;

            this.$emit('setDailyQuizIntegratedInfo', dailyQuizIntegratedInfo)
            this.emitter.emit("getDailyQuizAnswerHistoryList", param);
          }
        }
      });
    }
  }
}
</script>

<style>
  @import "@/assets/main/manage/DailyQuizManage.css";


</style>